// use this file to ovewrite bootstrap variables

$theme-colors: (
  "primary": #5F2AB2,
  "primarylight": #ECE2FC,
  "secondary_f": #E5D5FF33,
  "grey-dark": #464646,
  "border_form-item": #ccc
);

$input-border-color: #464646;
$form-label-font-weight: 500;
$text-muted: #7E7E7E;