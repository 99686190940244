:root {
  /* Themes */
  --primary: #5F2AB2;
  --primary-light: #e2d6f5;
  --primary-dark: #431e7e;
  --secondary-a: #5F2AB2;
  --secondary-b: #7D2BFF;
  --secondary-c: #9755FF;
  --secondary-d: #B180FF;
  --secondary-e: #CBAAFF;
  --secondary-f: #E5D5FF33;
  --secondary-g: #2E3139;
  --secondary-h: #FAF6FF;
  --background: #F4F2F2;
  --shadow-smooth: #2E313922;
  --text-primary: #242424;
  --text-secondary: #5c5c5c;
  --text-over-primary: #FAFAFA;

  /* Colors */
  --white: #FFF;
  --cyan: #88F3ED;
  --compose-cyan: #1F716C;
  --green: #00EF1C;
  --green-light: #DCFFE0;
  --orange: #FF4F1C;
  --orange-dark: #c22e05;
  --red: #ff0000;
  --orange-light: #FFDBD1;
  --yellow: #FFBF00;
  --yellow-light: #FFF1C7;
  --grey: #464646;
  --grey-opacity-70: rgba(70, 70, 70, 0.7);
  --grey-dark: #464646;
  --grey-light: #656464;
  --grey-lighter: #7E7E7E;
  --input-border: #86B7FE;
  --input-focus-shadow: #C2DBFE;
  --anchor-user-terms: #6588E2
}

/* Custom global overflow scroll */
::-webkit-scrollbar-track {
  background-color: var(--background);
  width: 5px;
}

::-webkit-scrollbar {
  background-color: var(--background);
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #AAA;
  border-radius: 10px;
  width: 5px;
}

/* Override bootstrap elements with platform theme */
.btn,
.btn:hover,
.btn:focus,
.btn:active {
  background-color: var(--primary);
  color: var(--text-over-primary);
  border-color: var(--primary);
  box-shadow: none;
  border-style: outset;
}

.accordion-button {
  background-color: var(--background);
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: var(--grey);
  background-color: var(--background);
}

.accordion-button::before {
  flex-shrink: 0;
  width: 0.75rem;
  height: 0.463rem;
  margin-right: 1rem;
  content: "";
  background-image: url('../images/filled-hardware-Vector.png');
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed)::before {
  background-image: url('../images/filled-hardware-Vector.png');
  transform: rotate(-180deg);
}

.accordion-button::after {
  content: "";
  width: 0;
  height: 0;
  margin-left: auto;
  background-image: none;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.accordion-body-platform {
  background-color: var(--background);
  padding: 1rem 1.25rem;
}

.form-switch .form-check-input {
  box-shadow: none;
}

.form-switch .form-check-input:checked {
  background-color: var(--primary);
  color: var(--text-over-primary);
  border-color: var(--primary);
  box-shadow: none;
  border-style: outset;
}

.form-control:focus {
  box-shadow: none;
}

/*
.form-switch .form-check-input:focus {
  background-color: var(--secondary-e);
  border: 1px solid #FFF;
}*/

/* link theme */
a,
a:hover,
a:focus {
  color: var(--primary);
}

.rbt-token {
  background-color: var(--secondary-e) !important;
  color: var(--primary) !important;
  text-transform: lowercase;
}