.swal-modal {
  width: 640px;
  min-height: 235px;
  max-height: 660px;
  background-color: #fff;
  padding-bottom: 20px;
}

.swal-content {
  padding: 0;
  margin: 0;
}

.swal-footer {
  padding: 0;
  margin: 0;
  display: flex;
}

.swal-button-container {
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  margin: 0;
  width: 100%;
}

.swal-button {
  font-size: 12px;
  color: #5F2AB2;
  background-color: #FFF;
  border: 1px solid #5F2AB2;
  padding: 5px 20px;
  border-radius: 4px;
}

.swal-button:not([disabled]):hover {
  background-color: #FFF;
}

.swal-button--cancel {
  margin-right: 12.5rem;
}

.popup-button-confirm {
  font-size: 12px;
  color: #FFF;
  background-color: #5F2AB2;
  padding: 5px 20px;
  border-radius: 4px;
  margin-left: 12.5rem;
}

.popup-button-confirm:not([disabled]):hover {
  background-color: #5F2AB2;
}

@media (max-width: 768px) {
  .swal-modal {
    max-width: calc(100% - 15px);
  }
}

@media (max-width: 640px) {
  .swal-button--cancel {
    margin-right: 10rem;
  }

  .popup-button-confirm {
    margin-left: 10rem;
  }
}

@media (max-width: 500px) {
  .swal-button--cancel {
    margin-right: 0;
  }

  .popup-button-confirm {
    margin-left: 0;
  }
}
